"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var employee_service_1 = require("../../services/employee.service");
var modal_service_1 = require("../../_services/modal.service");
var profile_model_1 = require("../../models/overview/profile.model");
var country_service_1 = require("../../services/country.service");
var common_service_1 = require("../../services/common.service");
var job_service_1 = require("../../services/job.service");
var candidate_service_1 = require("../../services/candidate.service");
var user_service_1 = require("../../services/user.service");
var permission_service_1 = require("../../services/permission.service");
var personaltype_service_1 = require("../../services/personaltype.service");
var company_service_1 = require("../../services/company.service");
var alert_service_1 = require("../../services/alert.service");
var checksystem_service_1 = require("../../services/checksystem.service");
var router_1 = require("@angular/router");
var zoom_service_1 = require("../../services/zoom.service");
var app_global_1 = require("../../app.global");
var RighttoworkcheckComponent = /** @class */ (function () {
    function RighttoworkcheckComponent(userService, employeeService, country, modalService, commonService, activatedRoute, formBuilder, jobdetails, candidatedetails, permissiondetails, dltypeService, companyService, alerts, check, router, route, zoomService) {
        var _this = this;
        this.userService = userService;
        this.employeeService = employeeService;
        this.country = country;
        this.modalService = modalService;
        this.commonService = commonService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.jobdetails = jobdetails;
        this.candidatedetails = candidatedetails;
        this.permissiondetails = permissiondetails;
        this.dltypeService = dltypeService;
        this.companyService = companyService;
        this.alerts = alerts;
        this.check = check;
        this.router = router;
        this.route = route;
        this.zoomService = zoomService;
        this.th = [];
        this.emailexist = false;
        this.mindob = new Date();
        this.maxdob = new Date();
        this.currentDate = new Date();
        this.empidexist = false;
        this.defaultVal = "";
        this.h1tag = "";
        this.loading = 0;
        this.pageOfItems = [];
        this.samecompany = false;
        this.requestDocSentMsg = "";
        this.right = new profile_model_1.righttowork();
        this.newlist = [];
        this.unassignedCases = [];
        this.selectedpage = 1;
        route.params.subscribe(function (params) {
            _this.emptype = params['type'];
            if (_this.emptype == 'visa') {
                _this.h1tag = "Visa";
            }
            else if (_this.emptype == 'passport') {
                _this.h1tag = "Passport";
            }
            else if (_this.emptype == 'document') {
                _this.h1tag = "Driving Licence";
            }
        });
    }
    RighttoworkcheckComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defaultVal = "";
        this.stage = 1;
        this.Userid = localStorage.getItem('userid');
        var permission = localStorage.getItem('ispermission');
        permission = JSON.parse(permission);
        // this.permission = permission[0];
        if (permission[0]['m124'] < 1) {
            this.router.navigate(['/permission-denied']);
        }
        this.timeValues = app_global_1.AppGlobals.TIME_VALUES;
        this.loading = 0;
        this.right = new profile_model_1.righttowork();
        this.meetingformGroup = this.formBuilder.group({
            startdate: ['', forms_1.Validators.required],
            starttime: ['', forms_1.Validators.required],
            endtime: ['', forms_1.Validators.required]
        });
        // this.emptype = this.activatedRoute.snapshot.paramMap.get("type");
        this.check.getbillinginformation({ 'serviceid': 4 }).subscribe(function (remaindata) {
            _this.remainingcheck = remaindata;
        });
        this.mindob.setDate(this.mindob.getDate() - 23741);
        this.maxdob.setDate(this.maxdob.getDate() - 574);
        this.employeeForm = this.formBuilder.group({
            fname: ['', forms_1.Validators.required],
            mname: [''],
            lname: ['', forms_1.Validators.required],
            empid: [''],
            emailid: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.email])],
            phonenumber: ['', forms_1.Validators.compose([forms_1.Validators.required])]
        });
        this.terminationForm = this.formBuilder.group({
            employeeid: ['', forms_1.Validators.required],
            terminationdate: ['', forms_1.Validators.required],
            terminationreason: ['', forms_1.Validators.required],
            oktorehire: ['', forms_1.Validators.required],
            regrettermination: ['', forms_1.Validators.required]
        });
        this.overviewData = new profile_model_1.Profile();
        this.overviewData.phonenumber = "+44";
        this.terminationDetail = new profile_model_1.Termination();
        this.country.getcountrylist().subscribe(function (country) {
            _this.countrylist = country;
        });
        this.jobdetails.getjobList().subscribe(function (jobdetails) {
            _this.joblist = jobdetails;
        });
        this.dltypeService.getactivepersonaltypelist().subscribe(function (dltypes) {
            _this.dltypelist = dltypes;
        });
        this.dltypeService.getdrivinglicenselist().subscribe(function (dlicense) {
            _this.dllist = dlicense;
        });
        this.permissiondetails.bindallmenu().subscribe(function (menudata) {
            var permissionstring = "[{";
            var perarray = [];
            menudata.forEach(function (valuemenu) {
                perarray.push('"' + valuemenu.clname + '": 0');
            });
            var perstr = perarray.join();
            permissionstring = permissionstring + perstr + "}]";
            _this.permissionstring = permissionstring;
        });
        this.getEmployees();
    };
    RighttoworkcheckComponent.prototype.getEmployees = function () {
        var _this = this;
        this.employeeService.getlistofemployeerigttowork().subscribe(function (employeedata) {
            // employeedata.forEach(function (value) {
            //   var tg;
            //   tg = JSON.parse(value.permission.toString());
            //   tg.forEach(function (value4) {
            //   });
            // });
            // this.data = employeedata;
            // this.record = this.data.length;
            // this.datalist = this.data; 
            // this.pageOfItems = this.data;
            // if(this.record < 30){
            //   this.pageOfItems = this.data;
            // }
            // else{
            //      this.items =this.data;
            // }      
            employeedata.forEach(function (element) {
                element.displayData = true;
                _this.pageOfItems.push(element);
            });
            _this.newlist = _this.pageOfItems;
            _this.unassignedCases = _this.paginator(_this.newlist, _this.selectedpage, 50);
        });
    };
    RighttoworkcheckComponent.prototype.searchData = function (searchKey) {
        var _this = this;
        var listdata = [];
        searchKey = searchKey.trim().toLowerCase();
        this.pageOfItems.forEach(function (element, index) {
            if (element.fname.trim().toLowerCase().includes(searchKey) ||
                element.mname.trim().toLowerCase().includes(searchKey) ||
                element.emailid.trim().toLowerCase().includes(searchKey) ||
                element.phonenumber.trim().toLowerCase().includes(searchKey)) {
                _this.pageOfItems[index].displayData = true;
            }
            else {
                _this.pageOfItems[index].displayData = false;
            }
        });
        listdata = this.pageOfItems.filter(function (option) {
            if (option.displayData)
                return true;
        });
        this.selectedpage = 1;
        this.newlist = listdata;
        this.unassignedCases = this.paginator(listdata, 1, 50);
    };
    RighttoworkcheckComponent.prototype.onFocusOutEvent = function (event) {
        var _this = this;
        this.userService.isCompanyEmailRegisterd(event.target.value).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = false;
                _this.employeeid = data.employeeid;
                if (data.samecompany) {
                    _this.samecompany = true;
                }
                // this.isFocus = true;
                _this.modalService.open('alreadyadd-request');
            }
            else {
                _this.disableBtn = true;
            }
        });
    };
    RighttoworkcheckComponent.prototype.onChangePage = function (pageOfItems) {
        // update current page of items
        this.pageOfItems = pageOfItems;
    };
    Object.defineProperty(RighttoworkcheckComponent.prototype, "f", {
        get: function () { return this.employeeForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RighttoworkcheckComponent.prototype, "g", {
        get: function () { return this.terminationForm.controls; },
        enumerable: true,
        configurable: true
    });
    RighttoworkcheckComponent.prototype.onVisaSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        // this.userService.isUserRegisterd(employeeform1.username).subscribe((data2: any) => {
        //   if (data2.sussess == 'success') {
        //     this.username = 'true';
        //     this.disableBtn = true;
        //   }else{
        this.username = '';
        this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.companyService.checkcompanyemployeeid({ 'empid': employeeform1.empid }).subscribe(function (checkempid) {
                    if (checkempid.sussess == 'success') {
                        _this.empidexist = true;
                        _this.disableBtn = true;
                    }
                    else {
                        _this.disableBtn = false;
                        _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (data) {
                            _this.modalService.close(modelid);
                            _this.getEmployees();
                        });
                    }
                });
            }
        });
        //   }
        // });
    };
    RighttoworkcheckComponent.prototype.focusFunction = function () {
        this.emailexist = false;
    };
    RighttoworkcheckComponent.prototype.onUserNameOutEvent = function (event) {
        var _this = this;
        if (event.target.value == '') {
            this.username = '';
        }
        else {
            console.log('rahul kumar tanwar');
            this.username = '';
            var dbvalue = { 'username': event.target.value };
            this.userService.isUserRegisterd(event.target.value).subscribe(function (data) {
                // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {
                if (data.sussess == 'false') {
                    _this.username = 'false';
                }
                else {
                    _this.username = 'true';
                }
            });
        }
    };
    RighttoworkcheckComponent.prototype.isEmailUnique = function (control) {
        var _this = this;
        var q = new Promise(function (resolve, reject) {
            _this.userService.isEmailRegisterd(control.value).subscribe(function () {
                resolve(null);
            }, function () { resolve({ 'isEmailUnique': true }); });
        });
        return q;
    };
    RighttoworkcheckComponent.prototype.validatenumber = function (data, type) {
        var _this = this;
        var phone_number = data;
        this.commonService.validateMobileNumber(phone_number).subscribe(function (data) {
            var getdata;
            getdata = data;
            if (type == 'phone') {
                _this.phonecheck = getdata.valid;
            }
        });
    };
    RighttoworkcheckComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    RighttoworkcheckComponent.prototype.sendEmployeeRequest = function (model_id, employee_id) {
        this.stage = 1;
        this.requestDocSentMsg = "";
        this.sendLinkEmployeeId = employee_id;
        this.modalService.open(model_id);
    };
    RighttoworkcheckComponent.prototype.sendrequestcharge = function (request_type) {
        var _this = this;
        if (this.loading == 0) {
            this.loading = 1;
            this.requestDocSentMsg = "";
            var ts = {
                employeeid: this.sendLinkEmployeeId,
                requesttype: request_type,
                is_rtw: 1
            };
            this.check.genratetokenforemployee(ts).subscribe(function (response) {
                if (response.success == 'success') {
                    _this.loading = 0;
                    _this.requestDocSentMsg = "Your request for " + request_type + " sent successfully.";
                }
                _this.loading = 0;
                _this.alerts.success('Application Request Sent Successfully');
                // this.modalService.close(id);
            });
        }
    };
    RighttoworkcheckComponent.prototype.sendrequest = function (employeeid, id) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            this.submitted = false;
            this.meetingformGroup.reset();
            this.modalService.open(id);
            this.defaultVal = "";
            this.right = new profile_model_1.righttowork();
            this.employeeid = employeeid;
            localStorage.removeItem('logid');
            this.router.navigate(['/right-to-work-app', employeeid]);
        }
    };
    Object.defineProperty(RighttoworkcheckComponent.prototype, "returnForm", {
        get: function () {
            return this.meetingformGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    RighttoworkcheckComponent.prototype.sendZoomLink = function (employeeid, id, meetingform) {
        var _this = this;
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.close(id);
            this.modalService.open('upgrade-plan');
        }
        else {
            this.submitted = true;
            if (this.meetingformGroup.invalid) {
                return;
            }
            var startdate = new Date(meetingform.startdate.setHours(meetingform.starttime));
            var enddate = new Date(meetingform.startdate.setHours(meetingform.endtime));
            this.loading = 1;
            this.zoomService.sendZoomLink({ "startdate": startdate, "enddate": enddate, "email": "fareed@complygate.co.uk", "employeeid": this.employeeid }).subscribe(function (data) {
                _this.loading = 0;
                _this.modalService.close(id);
                _this.modalService.open('custom-send-request');
            });
            this.employeeid = employeeid;
        }
    };
    RighttoworkcheckComponent.prototype.onAddEmployeeSubmit = function (employeeform1, modelid) {
        var _this = this;
        this.submitted = true;
        if (this.employeeForm.invalid) {
            return;
        }
        this.disableBtn = false;
        employeeform1.id = this.Userid;
        employeeform1.permission = this.permissionstring;
        this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe(function (data) {
            if (data.sussess == 'success') {
                _this.emailexist = true;
                _this.disableBtn = true;
            }
            else {
                _this.disableBtn = false;
                _this.employeeService.checkemployeecreate(employeeform1).subscribe(function (dataw) {
                    if (dataw.susses == 'true') {
                        _this.router.navigate(['/right-to-work-app', dataw.employeemasterid]);
                        _this.getEmployees();
                        _this.sendrequest(dataw.employeemasterid, 'sendrequestcharge');
                        _this.modalService.close(modelid);
                    }
                    else {
                        _this.alerts.error('Somthing went wrong. please try again after sometime');
                    }
                });
            }
        });
    };
    RighttoworkcheckComponent.prototype.viewemployeerighttoworkhistory = function (empid) {
        localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app-history-list', empid]);
    };
    RighttoworkcheckComponent.prototype.uploadByAdmin = function (emp_type, type, empid) {
        if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
            this.modalService.open('upgrade-plan');
        }
        else {
            if (type == 'upload_now') {
                localStorage.removeItem('logid');
                // this.router.navigate(['/right-to-work-check-details', empid]);
                this.router.navigate(['/right-to-work-app', empid]);
            }
            else if (type == 'add_new') {
                if (this.remainingcheck && this.remainingcheck.remiancheck < 1) {
                    this.modalService.open('upgrade-plan');
                }
                else {
                    this.phonecheck = '';
                    this.submitted = false;
                    this.employeeForm.reset({
                        fname: "",
                        mname: "",
                        lname: "",
                        emailid: "",
                        phonenumber: ""
                    });
                    this.modalService.open(empid);
                }
            }
        }
    };
    RighttoworkcheckComponent.prototype.sendrequestchargestage = function (stagechange) {
        this.loading = 1;
        this.stage = stagechange;
        this.loading = 0;
    };
    RighttoworkcheckComponent.prototype.paginator = function (data, current_page, per_page_items) {
        var items;
        items = data;
        var page = current_page || 1, per_page = per_page_items || 50, offset = (page - 1) * per_page, paginatedItems = items.slice(offset).slice(0, per_page_items), total_pages = Math.ceil(items.length / per_page);
        return {
            page: page,
            perpage: per_page,
            prepage: page - 1 ? page - 1 : null,
            nextpage: (total_pages > page) ? page + 1 : null,
            total: items.length,
            totalpages: total_pages,
            data: paginatedItems,
            totalpagesarray: Array(total_pages).fill(null).map(function (x, i) { return i + 1; })
        };
    };
    RighttoworkcheckComponent.prototype.selectpage = function (pagenumber) {
        this.selectedpage = pagenumber;
        this.unassignedCases = this.paginator(this.newlist, this.selectedpage, 50);
    };
    return RighttoworkcheckComponent;
}());
exports.RighttoworkcheckComponent = RighttoworkcheckComponent;
