<app-header></app-header>
<div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
           
            <a [routerLink]="['/search-personal-aml','aml']" class="backlist mb-4 mr-0" *ngIf="logid == '0'"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Search</a>
            <a [routerLink]="['/search-business-aml']" class="backlist mb-4 mr-0"  *ngIf="logid == '1'"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to AML, PEPs & Sanction Search</a>
            <a [routerLink]="['/search-personal-aml','adverse']" class="backlist mb-4 mr-0"  *ngIf="logid == '2'"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Adverse Media Search</a>
            <a [routerLink]="['/search-personal-aml','global']" class="backlist mb-4 mr-0"  *ngIf="logid == '3'"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Global Due Diligence Search</a>
            
            <div class="clearfix"></div>
            <div class="tabs__content">
                <div class="tabs__content__item active-tab">
                    <div class="content" *ngIf="atype == true">
                        <div class="row row-sm topheader justify-content-between">
                            <div class="col-lg-6" *ngIf="logid == '0'">
                                <h5 class="hedingcolor">Personal AML, PEPs & Sanction  Log                                
                                    <button type="button"  *ngIf="ismonitoring && showmonitor && logid != '2'" class="btn btn-primary com_btn" [innerHtml]="downloadtext"  style="background-color:green;"></button>
                                    <button type="button"  *ngIf="!ismonitoring && showmonitor  && logid != '2'"  class="btn btn-danger com_btn" [innerHtml]="downloadtext"  ></button>
                                </h5>
                            </div>

                            <div class="col-lg-6" *ngIf="logid == '2'">
                                <h5 class="hedingcolor">Adverse Media Log </h5>
                            </div>

                            <div class="col-lg-6" *ngIf="logid == '3'">
                                <h5 class="hedingcolor">Global Due Diligence Log </h5>
                            </div>


                            <div class="col-lg-4 text-right" >                               
                                <input placeholder="search.." [(ngModel)]="searchText" (ngModelChange)="searchData($event)"  class="form-control width35 ">
                                
                                <!-- [(ngModel)]="searchText"  (click)="searchData($event.target.value)"  class="form-control width35 "> -->
                            </div>

 
                            

                            <!-- <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>
                        <!-- <ng-container *ngIf="( datalist |  filter:{serialnumber: searchText,assetdescption:22222 searchText,categoryname: searchText }) as pageOfItems"> -->

                        <div *ngFor="let personal of unassignedCases.data; let i=index;">
    
    <!-- {{ outputnewset(personal)}} -->
    <ng-container *ngIf="personal.apiversion ==1">


    <div class="credit_data_showlog" *ngIf="personal.displayData" >
        <div class="job_list_section visa_request_section">

            <div class="job_detils_date width13">
                <div class="visa_user_details ">
                    <p class="job_address">Full Name</p>
                    <h4 *ngIf="personal.iscomplete==0 && logid !='1' && logid !='4'"><a [routerLink]="['/search-personal-aml',type,personal.id]">{{ personal.searchcondition.name }}</a></h4>
                    <h4 *ngIf="personal.iscomplete==1  && logid !='1' && logid !='4'"><a [routerLink]="['/creditsearchresult-new',personal.id]">{{ personal.searchcondition.name }}</a></h4>

                    <!-- <h4 *ngIf="personal.iscomplete==0 && logid =='2'"><a [routerLink]="['/search-personal-aml',type,personal.id]">{{ personal.searchcondition.name }}</a></h4>
                    <h4 *ngIf="personal.iscomplete==1  && logid =='2'"><a [routerLink]="['/creditsearchresult-new',personal.id]">{{ personal.searchcondition.name }}</a></h4> -->
                    <!-- <p class="phone text-success"> <b>Male</b></p>
                <p class="ninumber text-primary">Id - 5555 </p> -->
                    <!-- <span class="badge badge-danger">Indian</span> -->
                </div>
            </div>

            <div class="job_detils_date width10">
                <p class="job_address">Search Date</p>
                <p class="job_profile">{{ personal.createat | date: 'dd MMM yyyy' }}</p>
                
            </div>

            
            <div class="job_detils_date width10">
                <p class="job_address">Year of Birth</p>

                <p class="job_profile" *ngIf="personal.searchcondition.datesOfBirth">
                    <ng-container  *ngIf="personal.searchcondition.datesOfBirth != ''">
                    {{ personal.searchcondition.datesOfBirth  | date: 'yyyy' }}
                </ng-container>
                
                </p>
                <p class="job_profile" *ngIf="!personal.searchcondition.datesOfBirth"> N/A</p>
            </div>
            <!-- <div class="job_detils_date width10">
                <p class="job_address">Gender</p>

                <p class="job_profile">{{ personal.searchcondition.Male }}</p>
                <p class="job_profile" *ngIf="personal.searchcondition.Male == ''"> N/A</p>
            </div> -->

           


            <div class="job_detils_date width13">
                <p class="job_address">score</p>

                <p class="job_profile">{{ personal.searchcondition.score }}</p>
                <p class="job_profile" *ngIf="!personal.searchcondition.score"> N/A</p>
            </div>
            <div class="job_detils_date width13">
                <p class="job_address">Resource ID</p>

                <p class="job_profile">{{ personal.searchcondition.qrCode }}</p>
                <p class="job_profile" *ngIf="!personal.searchcondition.qrCode"> N/A</p>
            </div>
          
            <div class="job_detils_date width13">
                <p class="job_address">Date of Birth</p>
                <p class="job_profile">{{ personal.searchcondition.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                <p class="job_profile" *ngIf="!personal.searchcondition.DateOfBirth"> N/A</p>
            </div>
            <div class="job_detils_date width7">
                <p class="job_address">Country</p>

                <ng-container *ngIf="personal.searchcondition.countries">

                
                <p class="job_profile text-danger" *ngIf="personal.searchcondition.countries.length <=1">
                    <b>
                        <ng-container *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">
                            {{cn.countryname}}
                        </ng-container>
                    </b>
                </p>

                <div class="dropdown"  *ngIf="personal.searchcondition.countries.length >1">
                    <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Country +{{personal.searchcondition.countries.length}} <span _ngcontent-c2="" class="lnr lnr-chevron-down"></span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <p *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">{{cn.countryname}}</p> 
                        <!-- <p *ngIf="!cn.countryname "> No Country</p> -->
                    </div>
                  </div>

                </ng-container>
                <!-- <p class="job_profile text-danger multicolorg">
                      <span *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">{{cn.countryname}}</span>            
                </p> -->
                <p class="job_profile" *ngIf="personal.searchcondition.countries == ''"> N/A</p>
            </div>

            <div class="job_detils_date width100 mt-2 ">
                <p *ngIf="!personal.searchcondition.qrCode"  class="job_address">Data Set</p>
                <div class="datasetshow">
                    <!-- {{dataset(personal.searchcondition)}} -->
                    <ul *ngIf="!personal.searchcondition.qrCode" >
                        <li *ngFor="let cn of bindallpep(personal.searchcondition.datasets)">{{cn.name }}</li>
                    </ul>


                    <ul *ngIf="personal.searchcondition.qrCode" >
                        <li>&nbsp;</li>
                    </ul>



                    <ul >
                        <li *ngIf="ismonitoring && logid != '2'" > 
                            <button type="button" *ngIf="personal.ismonitoring && showmonitor" class="btn btn-danger com_btn" [innerHtml]="downloadtextnew[i]" (click)="openModal('pdf-payment-confirmation-fail',personal.id)"></button>
                            <button type="button" *ngIf="!personal.ismonitoring && showmonitor" class="btn btn-primary com_btn" [innerHtml]="downloadtextnew[i]" (click)="openModal('pdf-payment-confirmation',personal.id)"></button>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        
    </div>
</ng-container>

    <ng-container *ngIf="personal.apiversion ==0">



                        <div class="credit_data_showlog" *ngIf="personal.displayData" >
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width13">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Full Name</p>

                                        <h4><a [routerLink]="['/creditsearchresult',personal.id]">{{ personal.searchcondition.Forename }} {{ personal.searchcondition.Middlename }} {{ personal.searchcondition.Surname }}</a></h4>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Year of Birth</p>
                                    <p class="job_profile">{{ personal.searchcondition.YearOfBirth }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.YearOfBirth  == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Town/City</p>

                                    <p class="job_profile">{{ personal.searchcondition.City }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.City == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">County/State</p>

                                    <p class="job_profile">{{ personal.searchcondition.County }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.County == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date width13">
                                    <p class="job_address">Post/Post Code</p>

                                    <p class="job_profile">{{ personal.searchcondition.Postcode }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.Postcode == ''"> N/A</p>
                                </div>
                              
                                <div class="job_detils_date width13">
                                    <p class="job_address">Date of Birth</p>
                                    <p class="job_profile">{{ personal.searchcondition.DateOfBirth | date: 'dd MMM yyyy' }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.DateOfBirth == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date width7">
                                    <p class="job_address">Country</p>
                                    <p class="job_profile text-danger"><b>{{ personal.searchcondition.Country }}</b></p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.Country === ''"> N/A</p>
                                </div>
                               

                            <div class="job_detils_date width100 mt-2 ">
                                <p class="job_address">Data Set</p>
                                <div class="datasetshow">
                                    {{dataset(personal.searchcondition)}}
                                    <ul [innerHTML]="datasetlist"></ul>
                                </div>
                            </div>

                            </div>
                         
                        </div>
                    </ng-container>
                        </div> 

                       
                        <ul class="pagination_new" *ngIf="unassignedCases.totalpagesarray">
                       
                            <li (click)=selectpage(1) class="page-item"  [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-double-left" aria-hidden="true"></i></li>
                            <li (click)=selectpage(selectedpage-1)  class="page-item"   [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-left" aria-hidden="true"></i></li>
                            
                            <ng-container *ngFor="let pagesarray of unassignedCases.totalpagesarray;  let i=index">
                              <li *ngIf="(pagesarray <= selectedpage+((selectedpage==1)?4:2)) && (pagesarray >= selectedpage-((selectedpage==unassignedCases.totalpagesarray.length)?4:2))"
                                (click)=selectpage(pagesarray) [ngClass]="{ 'active':  pagesarray == selectedpage }" class="page-item">
                                {{pagesarray}}
                              </li>                           
                            </ng-container>
                  
                            <li (click)=selectpage(selectedpage+1)   [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                            <li (click)=selectpage(unassignedCases.totalpagesarray.length)  [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-double-right" aria-hidden="true"></i></li>              
                  
                                 
                        </ul>

                        <!-- </ng-container> -->
                        <div class="job_list_section visa_request_section" *ngIf="searchresult.length==0">
                            <div class="job_detils_date">
                                <p class="job_profile">No Record Found</p>
                            </div>
                        </div>

                      
                    </div>


                    <!-- <p class="job_profile"  *ngIf="!searchresult"> N/A</p> -->

                        <div class="browser-screen-loading-content" *ngIf="!searchresult" >
                            <div class="loading-dots dark-gray">
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>
                                <i></i>                                
                                <i></i>
                            </div>
                            <p>Loading</p>
                        </div>

                    <div class="content" *ngIf="atype == false">
                        <div class="row row-sm topheader justify-content-between">
                            <div class="col-lg-6">
                                <h5 class="hedingcolor">Business  AML, PEPs & Sanction  Log 


                                    <button type="button"  *ngIf="ismonitoring" class="btn btn-primary com_btn" [innerHtml]="downloadtext"  style="background-color:green;"></button>
                                <button type="button"  *ngIf="!ismonitoring" class="btn btn-danger com_btn" [innerHtml]="downloadtext"  ></button>
                                </h5>
                            </div>

                           
                            <div class="col-lg-4 text-right">
                                <input placeholder="search.."  [(ngModel)]="searchText" (ngModelChange)="searchData($event)"     class="form-control width35 ">


                            </div>
                           <!-- <div class="col-lg-2 text-right">                                                           
                                
                            </div>
                             <div class="col-lg-6">
                              <div class="input-group">
                                  <input placeholder="Search.." class="form-control">
                              </div>

                          </div> -->
                        </div>


                        
<div *ngFor="let personal of unassignedCases.data; let i=index;  ">
    
    



    <ng-container *ngIf="personal.apiversion ==1">

    <div class="credit_data_showlog" *ngIf="personal.displayData">
        <div class="job_list_section visa_request_section">
 
            <div class="job_detils_date width40">
                <div class="visa_user_details ">
                    <p class="job_address">Business Name</p>
                    <!-- <h4><a  [routerLink]="['/search-business-aml-details',personal.id]">{{ personal.name }} </a></h4> -->

                    <h4 *ngIf="personal.iscomplete==0"><a [routerLink]="['/search-business-aml',type,personal.id]">{{ personal.searchcondition.name }}</a></h4>

                    <h4 *ngIf="personal.iscomplete==1"><a [routerLink]="['/search-business-aml-details',personal.id]">{{ personal.searchcondition.name }}</a></h4>
                

                    


                    <p class="job_profile" *ngIf="personal.searchcondition.name == '' "> N/A</p>                   
                </div>
            </div>
           
            <div class="job_detils_date width20">
                <p class="job_address">Match</p>

                <p class="job_profile">{{ personal.searchcondition.match }}</p>
                <p class="job_profile" *ngIf="!personal.searchcondition.match "> N/A</p>
            </div>
            <div class="job_detils_date width20">
                <p class="job_address">Code</p>
                <p class="job_profile">{{ personal.searchcondition.qrCode }}</p>
                <p class="job_profile" *ngIf="!personal.searchcondition.qrCode"> N/A</p>
            </div>          
          
            <div class="job_detils_date width10">
                <p class="job_address">Country</p>


                <p class="job_profile text-danger" *ngIf="personal.searchcondition.countries.length <=1">
                    <b>
                        <ng-container *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">
                            {{cn.countryname}}
                        </ng-container>
                    </b>
                </p> 

                <div class="dropdown"  *ngIf="personal.searchcondition.countries.length >1">
                    <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Country +{{personal.searchcondition.countries.length}} <span _ngcontent-c2="" class="lnr lnr-chevron-down"></span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <p *ngFor="let cn of bindallcountry(personal.searchcondition.countries)">{{cn.countryname}}</p> 
                       
                    </div>
                  </div>

                <p class="job_profile" *ngIf="!personal.searchcondition.countries"> N/A</p>
            </div>
            <div class="job_detils_date width100">
                <p class="job_address">Data Set</p>

                <div class="datasetshow">                    
                    <ul >
                        <li *ngFor="let cn of bindallpep(personal.searchcondition.datasets)">{{cn.name }}</li>
                    </ul>


                    <ul >
                        <li *ngIf="ismonitoring"> 
                            <button type="button" *ngIf="personal.ismonitoring" class="btn btn-danger com_btn" [innerHtml]="downloadtextnew[i]" (click)="openModal('pdf-payment-confirmation-fail',personal.id)"></button>
                            <button type="button" *ngIf="!personal.ismonitoring" class="btn btn-primary com_btn" [innerHtml]="downloadtextnew[i]" (click)="openModal('pdf-payment-confirmation',personal.id)"></button>
                        </li>
                    </ul>


                </div>
            </div>         
          
         </div>
       
    </div>

</ng-container>


<ng-container *ngIf="personal.apiversion ==0">


                        <div class="credit_data_showlog" *ngIf="personal.displayData">
                            <div class="job_list_section visa_request_section">

                                <div class="job_detils_date width20">
                                    <div class="visa_user_details ">
                                        <p class="job_address">Business Name</p>

                                        <h4><a  [routerLink]="['/buscreditsearchresult',personal.id]">{{ personal.searchcondition.BusinessName }} </a></h4>
                                        <p class="job_profile" *ngIf="personal.searchcondition.BusinessName == '' "> N/A</p>
                                        <!-- <p class="phone text-success"> <b>Male</b></p>
                                    <p class="ninumber text-primary">Id - 5555 </p> -->
                                        <!-- <span class="badge badge-danger">Indian</span> -->
                                    </div>
                                </div>
                             
                                <div class="job_detils_date width10">
                                    <p class="job_address">Town/City</p>

                                    <p class="job_profile">{{ personal.searchcondition.City }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.City == ''"> N/A</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">County/State</p>
                                    <p class="job_profile">{{ personal.searchcondition.County }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.County == '' "> N/A</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Post/Post Code</p>
                                    <p class="job_profile">{{ personal.searchcondition.Postcode }}</p>
                                    <p class="job_profile" *ngIf="personal.searchcondition.Postcode == '' "> N/A</p>
                                </div>
                           
                            <div class="job_detils_date width10 mt-2">
                                <p class="job_address">Address</p>
                                <p class="job_profile">{{ personal.searchcondition.Address }}</p>
                                <p class="job_profile" *ngIf="personal.searchcondition.Address == '' "> N/A</p>
                            </div>
                            <!-- <div class="job_detils_date width10 mt-2">
                                <p class="job_address">Country</p>
                                <p class="job_profile text-danger"><b>{{ personal.searchcondition.Country }}</b></p>
                                <p class="job_profile" *ngIf="personal.searchcondition.Country == '' "> N/A</p>
                            </div>
                            <div class="job_detils_date width10">
                                <p class="job_address">Address</p>
                                <p class="job_profile">{{ personal.searchcondition.Address }}</p>
                                <p class="job_profile" *ngIf="personal.searchcondition.Address == '' "> N/A</p>
                            </div> -->
                            <div class="job_detils_date width100  mt-2">

                                <p class="job_address">Data Set</p>
                            <div class="datasetshow">
                                {{dataset(personal.searchcondition)}}
                                <ul [innerHTML]="datasetlist"></ul>                                    
                            </div>
                            </div>

                            </div>
                          
                        </div>

                    </ng-container>



</div>    
                    
                    
                       <!-- <div class="row" *ngIf="ispaging" style="margin-top:30px;">
                                <div class="col-lg-12 text-right">
                                    <jw-pagination  [items]="items" [pageSize]="50" [maxPages]="10" (changePage)="onChangePage($event)"></jw-pagination>
                                </div>
                            </div> -->
                    

                    <!-- <app-pagination [totalRecords]="searchresult.length" [recordsPerPage]="100" (onPageChange)="displayActivePage($event)"></app-pagination>  -->


                    
                    <ul class="pagination_new" *ngIf="unassignedCases.totalpagesarray">
                       
                        <li (click)=selectpage(1) class="page-item"  [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-double-left" aria-hidden="true"></i></li>
                        <li (click)=selectpage(selectedpage-1)  class="page-item"   [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-left" aria-hidden="true"></i></li>
                        
                        <ng-container *ngFor="let pagesarray of unassignedCases.totalpagesarray;  let i=index">
                          <li *ngIf="(pagesarray <= selectedpage+((selectedpage==1)?4:2)) && (pagesarray >= selectedpage-((selectedpage==unassignedCases.totalpagesarray.length)?4:2))"
                            (click)=selectpage(pagesarray) [ngClass]="{ 'active':  pagesarray == selectedpage }" class="page-item">
                            {{pagesarray}}
                          </li>                           
                        </ng-container>
              
                        <li (click)=selectpage(selectedpage+1)   [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                        <li (click)=selectpage(unassignedCases.totalpagesarray.length)  [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-double-right" aria-hidden="true"></i></li>              
              
                             
                    </ul>

                    <div class="job_list_section visa_request_section" *ngIf="searchresult.length==0">
                        <div class="job_detils_date">
                            <p class="job_profile">No Record Found</p>
                        </div>
                    </div>



                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>





<jw-modal id="pdf-payment-confirmation" class="modal sendrequestcharge" role="dialog">
    <div role="document" class="formgroup">                         
        <div class="modal-content">                         
            <div class="modal-body text-center">                         
                <div class="container">
                    <div class="row">                         
                        <div class="col-lg-12 spaceset">
                            <div class="check">
                                <span class="lnr lnr-checkmark-circle"></span>
                            </div>
                            <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                            <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                            <h4 class="mb-2">Are you sure to active the AML monitoring </h4>
                            <p class="">{{errormsg}}</p>                         
                        </div>
                        <div class="text-center m-auto">
                            <button type="submit"  class="btn com_btn btn-primary" (click)="onActive('pdf-payment-confirmation')"  [disabled]="!disableBtn"     name="updateform"  > Confirm</button>
                            <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation');" > Cancel</button>
                        </div>                           
                    </div>                         
                </div>                         
            </div>        
        </div>                         
    </div>                         
  </jw-modal>
  
  
  
  <jw-modal id="pdf-payment-confirmation-fail" class="modal sendrequestcharge" role="dialog">
    <div role="document" class="formgroup">                         
        <div class="modal-content">                         
            <div class="modal-body text-center">                         
                <div class="container">
                    <div class="row">                         
                        <div class="col-lg-12 spaceset">
                            <div class="check">
                                <span class="lnr lnr-checkmark-circle"></span>
                            </div>
                            <!-- <p class="textinfoma">Per Click Charge <span class="charge">£5</span> </p> -->
                            <!-- <p class="text-center short">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs</p> -->
                            <h4 class="mb-2">Are you sure to Deactive the AML monitoring </h4>
                            <p class="">{{errormsg}}</p>                         
                        </div>
                        <div class="text-center m-auto">
                            <button type="submit"  class="btn com_btn btn-primary" (click)="onDeactive('pdf-payment-confirmation-fail')"  [disabled]="!disableBtn"  name="updateform"  > Confirm</button>
                            <button type="submit"  class="btn com_btn btn-secondary"(click)="closeModal('pdf-payment-confirmation-fail');" > Cancel</button>
                        </div>                           
                    </div>                         
                </div>                         
            </div>        
        </div>                         
    </div>                         
  </jw-modal>