"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var checkservice_service_1 = require("../../../services/checkservice.service");
var common_1 = require("@angular/common");
var RighToWorkHistoryListComponent = /** @class */ (function () {
    function RighToWorkHistoryListComponent(activatedRoute, checkService, router, datePipe) {
        this.activatedRoute = activatedRoute;
        this.checkService = checkService;
        this.router = router;
        this.datePipe = datePipe;
        this.unassignedCases = [];
        this.selectedpage = 1;
    }
    RighToWorkHistoryListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.empid = this.activatedRoute.snapshot.paramMap.get("empid");
        var data = { employeeid: this.empid };
        this.checkService.getrighttoworkhistorybyemployeeid(data).subscribe(function (righttoworklog) {
            righttoworklog.forEach(function (element) {
                element.displayData = true;
            });
            _this.listemployee = righttoworklog;
            _this.listemployee.filter(function (option) {
                if (option.displayData)
                    return true;
            });
            _this.unassignedCases = _this.paginator(_this.listemployee, _this.selectedpage, 50);
        });
    };
    RighToWorkHistoryListComponent.prototype.shouldDisplayDate = function (date) {
        if (this.datePipe.transform(date, 'yyyy-MM-dd') >= this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) {
            return true;
        }
        return false;
    };
    RighToWorkHistoryListComponent.prototype.searchData = function (searchKey) {
        var _this = this;
        var listdata = [];
        searchKey = searchKey.trim().toLowerCase();
        this.listemployee.forEach(function (element, index) {
            if (element.employee.fname.trim().toLowerCase().includes(searchKey) ||
                element.employee.mname.trim().toLowerCase().includes(searchKey) ||
                element.employee.lname.trim().toLowerCase().includes(searchKey) ||
                element.employee.emailid.trim().toLowerCase().includes(searchKey) ||
                element.employee.phonenumber.trim().toLowerCase().includes(searchKey) ||
                element.nationality.countryname.trim().toLowerCase().includes(searchKey)) {
                _this.listemployee[index].displayData = true;
            }
            else {
                _this.listemployee[index].displayData = false;
            }
        });
        listdata = this.listemployee.filter(function (option) {
            if (option.displayData)
                return true;
        });
        this.unassignedCases = this.paginator(listdata, 1, 50);
    };
    RighToWorkHistoryListComponent.prototype.completerightowork = function (logid) {
        localStorage.SetItem('logid', logid);
        this.router.navigate(['/right-to-work-app-history']);
    };
    RighToWorkHistoryListComponent.prototype.viewemployeerighttoworkhistory = function (empid) {
        localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app-history', empid]);
    };
    RighToWorkHistoryListComponent.prototype.completerighttoworkhistory = function (logid, empid) {
        localStorage.setItem('logid', logid);
        // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
        this.router.navigate(['/right-to-work-app', empid]);
    };
    RighToWorkHistoryListComponent.prototype.paginator = function (data, current_page, per_page_items) {
        var items;
        items = data;
        var page = current_page || 1, per_page = per_page_items || 50, offset = (page - 1) * per_page, paginatedItems = items.slice(offset).slice(0, per_page_items), total_pages = Math.ceil(items.length / per_page);
        return {
            page: page,
            perpage: per_page,
            prepage: page - 1 ? page - 1 : null,
            nextpage: (total_pages > page) ? page + 1 : null,
            total: items.length,
            totalpages: total_pages,
            data: paginatedItems,
            totalpagesarray: Array(total_pages).fill(null).map(function (x, i) { return i + 1; })
        };
    };
    RighToWorkHistoryListComponent.prototype.selectpage = function (pagenumber) {
        this.selectedpage = pagenumber;
        this.unassignedCases = this.paginator(this.listemployee, this.selectedpage, 50);
    };
    return RighToWorkHistoryListComponent;
}());
exports.RighToWorkHistoryListComponent = RighToWorkHistoryListComponent;
