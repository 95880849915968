import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../_services/modal.service';
import { Profile, Termination,righttowork } from '../../models/overview/profile.model';
import { CountryService } from '../../services/country.service';
import { CommonService } from '../../services/common.service';
import { JobService } from '../../services/job.service';
import { CandidateService } from '../../services/candidate.service';
import { UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { PersonaltypeService } from '../../services/personaltype.service';
import { CompanyService } from '../../services/company.service';
import { AlertService } from '../../services/alert.service';
import { ChecksystemService } from '../../services/checksystem.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Routes, RouterModule } from '@angular/router';
import { ZoomService } from '../../services/zoom.service';
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-righttoworkcheck',
  templateUrl: './righttoworkcheck.component.html',
  styleUrls: ['./righttoworkcheck.component.css']
})
export class RighttoworkcheckComponent implements OnInit {

  data: any;  
  username:any;
  datalist:any;
  submitted: any;
  employeeForm: FormGroup;
  terminationForm: FormGroup;
  overviewData: any;
  terminationDetail: any;
  countrylist: any;
  dltypelist: any;
  dllist: any;
  joblist: any;
  employeeid:any;
  candidatelist: any;
  Userid: any;
  th = [];
  ismail: any;
  emailexist = false;
  public searchText: string;
  public searchTextcountry: string;
  permissionstring: any;
  mindob = new Date();
  maxdob = new Date();
  currentDate = new Date();
  phonecheck: any;
  empidexist = false;
  disableBtn: any;
  permission: any;
  empperm: any;
  defaultVal: any = "";
  record:number;
  emptype:any;
  items: Array<any>;
  h1tag: any = "";
  remainingcheck: any;
  sendLinkEmployeeId: any;
  loading:any=0;
  pageOfItems: any =[];
  meetingformGroup: FormGroup;
  timeValues: any;
  defaultValue:any;
  samecompany: any = false;
  requestDocSentMsg: any = "";
  right = new righttowork();
  stage:any;


  newlist: any =[];
  unassignedCases: any = [];
  selectedpage: number = 1;

  constructor(
    private userService: UserService, 
    private employeeService: EmployeeService, 
    private country: CountryService, 
    private modalService: ModalService, 
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute, 
    private formBuilder: FormBuilder, 
    private jobdetails: JobService, 
    private candidatedetails: CandidateService,
    private permissiondetails: PermissionService,
    private dltypeService: PersonaltypeService,
    private companyService: CompanyService,
    private alerts: AlertService,    
    private check: ChecksystemService,    
    private router : Router,    
    private route: ActivatedRoute,
    private zoomService: ZoomService
    ) {

      route.params.subscribe(params => {
        this.emptype = params['type'];
        if(this.emptype == 'visa')
        {
          this.h1tag = "Visa";
        }
        else if(this.emptype == 'passport')
        {
          this.h1tag = "Passport";
        }
        else if(this.emptype == 'document')
        {
          this.h1tag = "Driving Licence";
        }
      });
     }

  ngOnInit() {
    this.defaultVal ="";
    this.stage =1;
    this.Userid = localStorage.getItem('userid');
    var permission = localStorage.getItem('ispermission');
    permission = JSON.parse(permission);
    // this.permission = permission[0];
    if(permission[0]['m124'] < 1)
    {
      this.router.navigate(['/permission-denied']);
    }
    this.timeValues = AppGlobals.TIME_VALUES;
    this.loading =0;
    this.right =new righttowork();
    this.meetingformGroup = this.formBuilder.group({
      startdate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required]
    });
    // this.emptype = this.activatedRoute.snapshot.paramMap.get("type");
    this.check.getbillinginformation({ 'serviceid': 4 }).subscribe((remaindata: any) => { 
      this.remainingcheck = remaindata;
    });

    this.mindob.setDate(this.mindob.getDate() - 23741);
    this.maxdob.setDate(this.maxdob.getDate() - 574);

    this.employeeForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      empid: [''],
      emailid: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: ['', Validators.compose([Validators.required])]
    });

    this.terminationForm = this.formBuilder.group({
      employeeid: ['', Validators.required],
      terminationdate: ['', Validators.required],
      terminationreason: ['', Validators.required],
      oktorehire: ['', Validators.required],
      regrettermination: ['', Validators.required]
    });

    this.overviewData = new Profile();
    this.overviewData.phonenumber = "+44";
    this.terminationDetail = new Termination();

    this.country.getcountrylist().subscribe((country: any) => {
      this.countrylist = country;
    });

    this.jobdetails.getjobList().subscribe((jobdetails: any) => {
      this.joblist = jobdetails;
    });

    this.dltypeService.getactivepersonaltypelist().subscribe((dltypes: any) => {
      this.dltypelist = dltypes;
    });

    this.dltypeService.getdrivinglicenselist().subscribe((dlicense: any) => {
      this.dllist = dlicense;
    });

    this.permissiondetails.bindallmenu().subscribe((menudata: any) => {
      var permissionstring = "[{";
      var perarray = [];
      menudata.forEach(function (valuemenu) {
        perarray.push('"' + valuemenu.clname + '": 0');
      });
      
      var perstr = perarray.join();
      permissionstring = permissionstring + perstr + "}]";
      this.permissionstring = permissionstring;
    });

    this.getEmployees();

  }

  getEmployees()
  {
    this.employeeService.getlistofemployeerigttowork().subscribe((employeedata: any) => {
    
    
      // employeedata.forEach(function (value) {
      //   var tg;
      //   tg = JSON.parse(value.permission.toString());
      //   tg.forEach(function (value4) {
      //   });
      // });

      
      // this.data = employeedata;
      // this.record = this.data.length;
      // this.datalist = this.data; 
      // this.pageOfItems = this.data;

      // if(this.record < 30){
      //   this.pageOfItems = this.data;
      // }
      // else{
      //      this.items =this.data;
      // }      
      
      employeedata.forEach(element => {       
        element.displayData = true;
         this.pageOfItems.push(element);     
      });
               
      this.newlist = this.pageOfItems;

       this.unassignedCases = this.paginator(this.newlist,this.selectedpage,50);


    });
  }


  
  searchData(searchKey:any)
  {

    var listdata = [];

    searchKey = searchKey.trim().toLowerCase();
 

     
      this.pageOfItems.forEach((element, index) => {

        
         if(
          element.fname.trim().toLowerCase().includes(searchKey) ||
          element.mname.trim().toLowerCase().includes(searchKey) ||
          element.emailid.trim().toLowerCase().includes(searchKey) ||
          element.phonenumber.trim().toLowerCase().includes(searchKey)  
          )
        {
          this.pageOfItems[index].displayData = true;
        }
        else
        {
          this.pageOfItems[index].displayData = false;
        }
      });


      
      listdata=  this.pageOfItems.filter(      
        option => { 
            if(option.displayData)
              return true;
        });
        this.selectedpage=1;

        this.newlist = listdata;

        this.unassignedCases = this.paginator(listdata,1,50);


    
  }
  
  onFocusOutEvent(event: any)
  {
    this.userService.isCompanyEmailRegisterd(event.target.value).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = false;
        this.employeeid = data.employeeid;
        if(data.samecompany)
        {
          this.samecompany = true;
        }
        // this.isFocus = true;
        this.modalService.open('alreadyadd-request');
      }else
      {
        this.disableBtn = true;        
      }      
    });    
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  get f() { return this.employeeForm.controls; }

  get g() { return this.terminationForm.controls; }

  onVisaSubmit(employeeform1: any, modelid: any) {
    this.submitted = true;
    if (this.employeeForm.invalid) {
      return;
    }
    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;



    // this.userService.isUserRegisterd(employeeform1.username).subscribe((data2: any) => {

    //   if (data2.sussess == 'success') {
    //     this.username = 'true';
    //     this.disableBtn = true;
    //   }else{

        this.username = '';

    this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {
        this.companyService.checkcompanyemployeeid({'empid': employeeform1.empid}).subscribe((checkempid: any) => {
          if(checkempid.sussess == 'success')
          {
            this.empidexist = true; 
            this.disableBtn = true;
          }
          else
          {
            this.disableBtn = false;
            this.employeeService.checkemployeecreate(employeeform1).subscribe((data: any) => {
              this.modalService.close(modelid);
              this.getEmployees();
            });
          }
          
        })
        
      }
    });

//   }
// });



  }
  
  focusFunction() {
    this.emailexist = false;
  }


  
  onUserNameOutEvent(event: any)
  {       
    if(event.target.value == '')
    {
        this.username ='';
    }else
    {

      console.log('rahul kumar tanwar');
      this.username ='';
      var dbvalue = { 'username': event.target.value}
      this.userService.isUserRegisterd(event.target.value).subscribe((data: any) => {
    // this.userService.isUserRegisterd(event.target.value).subscribe((data2: any) => {

      if (data.sussess == 'false') {
        this.username = 'false';
      }else
      {
        this.username = 'true';
      }
    });
  }
  }


  isEmailUnique(control: FormControl) {
    const q = new Promise((resolve, reject) => {
      this.userService.isEmailRegisterd(control.value).subscribe(() => {
        resolve(null);
      }, () => { resolve({ 'isEmailUnique': true }); });

    });
    return q;
  }


  validatenumber(data, type) {
    var phone_number = data;
    this.commonService.validateMobileNumber(phone_number).subscribe((data: any) => {
      var getdata: any;
      getdata = data;
     
      if (type == 'phone') {
        this.phonecheck = getdata.valid;
      }
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  sendEmployeeRequest(model_id, employee_id)
  {
    this.stage =1;
    this.requestDocSentMsg = "";
    this.sendLinkEmployeeId = employee_id;
    this.modalService.open(model_id);
  }

  sendrequestcharge(request_type)
  {
    if(this.loading ==0){
    this.loading = 1;
    this.requestDocSentMsg = "";
    var ts = {
      employeeid: this.sendLinkEmployeeId, 
      requesttype: request_type,
      is_rtw: 1
    };

    this.check.genratetokenforemployee(ts).subscribe((response: any) => { 
      if(response.success == 'success')
      {this.loading = 0;
        this.requestDocSentMsg = "Your request for "+request_type+" sent successfully.";
      }this.loading = 0;
      this.alerts.success('Application Request Sent Successfully');
      // this.modalService.close(id);
    });
    }
  }


  sendrequest(employeeid, id)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.submitted = false;
      this.meetingformGroup.reset();
      this.modalService.open(id);
      this.defaultVal="";
      this.right =new righttowork();
      this.employeeid = employeeid;
      localStorage.removeItem('logid');

      this.router.navigate(['/right-to-work-app', employeeid]);



    }
  }

  get returnForm() {
    return this.meetingformGroup.controls;
  }

  sendZoomLink(employeeid, id, meetingform)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.close(id);
      this.modalService.open('upgrade-plan');
    }
    else
    {
      this.submitted = true;
      if (this.meetingformGroup.invalid) {
        return;
      }
      var startdate = new Date(meetingform.startdate.setHours(meetingform.starttime));
      var enddate = new Date(meetingform.startdate.setHours(meetingform.endtime));
      this.loading =1;
      this.zoomService.sendZoomLink({"startdate": startdate, "enddate": enddate, "email":"fareed@complygate.co.uk", "employeeid": this.employeeid}).subscribe((data: any) => {
        
          this.loading =0;
          this.modalService.close(id);
          
          this.modalService.open('custom-send-request');
      });
      this.employeeid = employeeid;
    }
  }


  
  onAddEmployeeSubmit(employeeform1: any, modelid: any) {
    this.submitted = true; 

    if (this.employeeForm.invalid) {
      return;
    }

    this.disableBtn = false;
    
    employeeform1.id = this.Userid;
    employeeform1.permission = this.permissionstring;
    this.userService.isCompanyEmailRegisterd(employeeform1.emailid).subscribe((data: any) => {
      if (data.sussess == 'success') {
        this.emailexist = true; 
        this.disableBtn = true;
      }
      else {       
          this.disableBtn = false;
          this.employeeService.checkemployeecreate(employeeform1).subscribe((dataw: any) => {
            if(dataw.susses == 'true'){
              this.router.navigate(['/right-to-work-app', dataw.employeemasterid]);
              this.getEmployees();
              this.sendrequest(dataw.employeemasterid,'sendrequestcharge');
              this.modalService.close(modelid);
          }else
          { this.alerts.error('Somthing went wrong. please try again after sometime');}
            
          });
      }
    });
  }

  
  viewemployeerighttoworkhistory(empid)
  { 
    localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app-history-list', empid]);
  }


  uploadByAdmin(emp_type, type, empid)
  {
    if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
    {
      this.modalService.open('upgrade-plan');
    }
    else
    {
      if(type == 'upload_now')
      {

       
        localStorage.removeItem('logid');
        // this.router.navigate(['/right-to-work-check-details', empid]);
        this.router.navigate(['/right-to-work-app', empid]);
      }
      else if(type == 'add_new')
      {
        if(this.remainingcheck && this.remainingcheck.remiancheck < 1)
        {
          this.modalService.open('upgrade-plan');
        }
        else
        {
          this.phonecheck = '';
          this.submitted = false;
          this.employeeForm.reset({
            fname: "",
            mname: "",
            lname: "",
            emailid: "",
            phonenumber: ""
          });
          this.modalService.open(empid);          
        }
      }
      
    }
  }

  sendrequestchargestage(stagechange){
    this.loading =1;
    this.stage =stagechange;
    this.loading =0;
  }


  
  paginator(data, current_page, per_page_items) {
    
    let items;   
    items = data
    let page = current_page || 1,
    per_page = per_page_items || 50,
    offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);
  
    return {
      page: page,
      perpage: per_page,
      prepage: page - 1 ? page - 1 : null,
      nextpage: (total_pages > page) ? page + 1 : null,
      total: items.length,
      totalpages: total_pages,
      data: paginatedItems,
      totalpagesarray : Array(total_pages).fill(null).map((x,i)=>i+1)
    };
  }

  selectpage(pagenumber){
    this.selectedpage = pagenumber    
      this.unassignedCases = this.paginator(this.newlist,this.selectedpage,50);   
  }
}

