<div class="bg">
  <app-header></app-header>
  <div class="section-wrapper">
    <div class="container">
        <div class="tabs" id="tabs">
            <!-- <a [routerLink]="['/request/request-list']" class="backlist mb-4 mr-0"> <i aria-hidden="true" class="fa fa-angle-left"></i> Back to Request List</a> -->
            <div class="clearfix"></div>
            <div class="tabs__content">

                <div class="tabs__content__item active-tab">
                    <div class="content">
                        <div class="row row-sm topheader">                            
                            <div class="col-lg-5 text-right">                          

                                <app-checkcountshow></app-checkcountshow>
                            
                            </div>
                            <div class="col-lg-7 text-right">
                              <div class="d-flex justify-content-end align-items-center">
                                <button class="btn com_btn btn-success"[routerLink]="['/right-to-work-check']"  type="button">
                                    <span class="lnr lnr-history pr-1">                                        
                                    </span> Back to Right To Work Check
                                </button>
                              </div>                             
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 aligncenter mb-3 justify-content-between">                            
                                <h5 class="hedingcolor">Right to Work check History</h5>       
                                <input type="text" [(ngModel)]="searchText" (ngModelChange)="searchData($event)"  class="form-control width35" placeholder="Search">                      
                          </div>                               
                        </div>


                        <div class="browser-screen-loading-content" *ngIf="!listemployee" >
                          <div class="loading-dots dark-gray">
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>
                              <i></i>                                
                              <i></i>
                          </div>
                          <p>Loading</p>
                      </div>


                        <div>
                          <ng-container  *ngFor="let visas of unassignedCases.data; let i=index ;" >

                            <div class="job_list_section idproof_request_section visa_request_section pb-2 pt-2 oddeven" *ngIf="visas.displayData" >
                            
                             
                            <div class="job_detils_date width22">
                                <h5 class="job_profile font_17">{{visas.employee.fname}} {{visas.employee.mname}}
                                    {{visas.employee.lname}}</h5>
                                <p class="email"><i aria-hidden="true" class="fa fa-envelope-o"></i>
                                    {{visas.employee.emailid}}</p>
                                <p class="phone"><i aria-hidden="true" class="fa fa-phone"></i>
                                    {{visas.employee.phonenumber}}</p>
                            </div>                           
                            
                            
                                <div class="job_detils_date width10">
                                    <p class="job_address">Right To Work</p>
                                    <p class="job_profile" *ngIf="visas.isrighttowork == 0">Normal Check</p>
                                    <p class="job_profile"  *ngIf="visas.isrighttowork == 1 && !shouldDisplayDate(visas.createat)">Adjusted check</p>
                                    <p class="job_profile"  *ngIf="visas.isrighttowork == 1 && shouldDisplayDate(visas.createat) ">IDVT Check</p>
                                    <p class="job_profile"  *ngIf="visas.isrighttowork == 2">Share Code</p>
                                </div>
                                <div class="job_detils_date width10">
                                    <p class="job_address">Type</p>
                                    <p class="job_profile" *ngIf="visas.isrighttoworktype == 0">Initial check</p>
                                    <p class="job_profile"  *ngIf="visas.isrighttoworktype == 1">Follow-up check</p>
                                </div>
                               
                                <div class="job_detils_date width10">
                                    <p class="job_address">Check Date</p>
                                    <p class="job_profile">{{visas.createat | date: 'dd MMM yyyy'}}</p>
                                </div>

                               <div class="job_detils_date width10">
                                    <p class="job_address">Country</p>
                                    <p class="job_profile">{{visas.nationality.countryname}}</p>
                                </div>                              

                                <div class="job_detils_date width10">
                                  <button (click)="viewemployeerighttoworkhistory(visas.id)" *ngIf="visas.anslist" class="btn btn-success com_btn small_btn width100" style="padding: 4px 10px;">View Report</button>

                                  <button (click)="completerighttoworkhistory(visas.id,visas.employee.employeemasterid)" *ngIf="!visas.anslist && visas.isrighttowork == 1 " class="btn btn-success com_btn small_btn width100" style="padding: 4px 10px;">Resend Request</button>

                                  <button (click)="completerighttoworkhistory(visas.id,visas.employee.employeemasterid)" *ngIf="!visas.anslist && visas.isrighttowork != 1 " class="btn btn-success com_btn small_btn width100" style="padding: 4px 10px;">Complete Check</button>
                                  

                                </div>

                              


                            </div>    
                          </ng-container>
                      </div> 



                      <ng-container *ngIf="listemployee">
                        <div class="job_list_section visa_request_section" *ngIf="listemployee.length == 0">
                              <div class="job_detils_date">
                                  <p class="job_profile">No Record Found</p>
                              </div>
                          </div>
                      </ng-container>


                      <ul class="pagination_new" *ngIf="unassignedCases.totalpagesarray">
                       
                        <li (click)=selectpage(1) class="page-item"  [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-double-left" aria-hidden="true"></i></li>
                        <li (click)=selectpage(selectedpage-1)  class="page-item"   [ngClass]="{ 'disabled':  selectedpage == 1 }" ><i  class="fa fa-angle-left" aria-hidden="true"></i></li>
                        
                        <ng-container *ngFor="let pagesarray of unassignedCases.totalpagesarray;  let i=index">
                          <li *ngIf="(pagesarray <= selectedpage+((selectedpage==1)?4:2)) && (pagesarray >= selectedpage-((selectedpage==unassignedCases.totalpagesarray.length)?4:2))"
                            (click)=selectpage(pagesarray) [ngClass]="{ 'active':  pagesarray == selectedpage }" class="page-item">
                            {{pagesarray}}
                          </li>                           
                        </ng-container>
              
                        <li (click)=selectpage(selectedpage+1)   [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
                        <li (click)=selectpage(unassignedCases.totalpagesarray.length)  [ngClass]="{ 'disabled':  selectedpage == unassignedCases.totalpagesarray.length }" class="page-item"><i class="fa fa-angle-double-right" aria-hidden="true"></i></li>              
              
                             
                    </ul>
                    
                      
                      </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>



  </div>
