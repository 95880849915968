import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import {  DatePipe } from '@angular/common';
import { LeaveService } from '../services/leave.service';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, setYear, setMonth } from 'date-fns';
import { Subject } from 'rxjs/Subject';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarEvent, CalendarEventAction,  CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Leave,Leavecount } from '../models/leave/leave.model';
import { ModalService } from '../_services/modal.service';
import { CustomerService } from '../services/customer.service';
import { AlertService } from '../services/alert.service';
import { AppGlobals } from '../app.global';
//import { AlertsService } from 'angular-alert-module'; from 'angular-alert-module';
import { Router } from '@angular/router';
import { MatInput } from '@angular/material';


@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css']
})
export class LeaveComponent {
  // @ViewChild('startPicker', {
  //   read: MatInput
  // }) startPicker: MatInput;
  
  @ViewChild('endPicker', {
    read: MatInput
  }) endPicker: MatInput;

  constructor(
    private modal: NgbModal, 
    private data: LeaveService, 
    private formBuilder: FormBuilder, 
    private modalService: ModalService,
    private alerts: AlertService,
    private router: Router,
    private datePipe: DatePipe,
    private customer: CustomerService
  ) { }
  Userid: any;
  event: CalendarEvent;
  leave: any;
  leaveformGroup: FormGroup;
  remarkformGroup: FormGroup;
  leaves: CalendarEvent[] = [];
  leavetypelist: any[];
  leavelistreport: any[];
  classactive: string;
  dayexpense: any[];
  year: number;
  minstartDate = new Date();
  minendDate = new Date();
  leaveavailabledate = new Date();
  submitted = false;
  remarksubmit = false;
  remarksofleave: any;
  remarksholidayid: any;
  month: any;
  disableBtn: any;
  defaultVal: any = "";
  linemanager: any;
  leavecount:any;
  yearList: any;

  ngOnInit() {
    this.yearList = AppGlobals.YEAR_VALUES;
    var userid = localStorage.getItem('userid');
    if (!userid) {
      this.router.navigate(['/login']);
    }

    var ctype  = parseInt(localStorage.getItem('ctype'));
    if(ctype == 1){
      this.router.navigate(['/check-dashboard']);
    }
    
    //this.minstartDate.setDate(this.minstartDate.getDate() + 1);
    this.leaveavailabledate.setDate(this.leaveavailabledate.getDate() - 1465);
    this.classactive = (new Date()).getMonth().toString();
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
    this.leaveformGroup = this.formBuilder.group({
      id: ['', Validators.required],
      employeeid: ['', Validators.required],
      holidaytype: ['', Validators.required],
      title: ['', Validators.required],
      halfday: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      holidayremark: ['', Validators.required],
      holidayremarkid: ['', Validators.required]
    });

    this.remarkformGroup = this.formBuilder.group({
      remarksholidayid: ['', Validators.required],
      holidayremark: ['', Validators.required]
      });
      this.Userid = localStorage.getItem('userid');
      const sstatustype = { 'status': '1', 'id': this.Userid } 
    this.data.getleavetypelist(sstatustype).subscribe((leavelist: any) => {      
      this.leavetypelist = leavelist;
    });
    this.bindallData();
    this.bindalldatabymonth();
  }
  openlinemanagerModal(id: string) 
  { 
    
    this.customer.getLineEmployeeDetails().subscribe((lsmanager: any) => {


      // console.log('>>>>>>> rahul',lsmanager);
      // console.log(lsmanager);
      this.linemanager = lsmanager;

    });
    this.modalService.open(id);
  }

  bindalldatabymonth() {
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.data.getemployeeleavereport(data).subscribe((data: any) => {
      this.leavelistreport = data;
    });
  }

  bindallData() {

    this.leavecount = new Leavecount();
    this.data.getemployeeleave().subscribe((data: any) => {
      // console.log(data);
      this.leaves = []; //.destroy();// =null;
      // console.log(data);
      data.forEach(element => {
        this.leaves.push({
          id: element.employeeholidayapplyid,
          title: element.title,
          start: new Date(element.startdate),
          end: new Date(element.enddate),
          color: { primary: element.hcolor, secondary: element.fcolor },
          allDay: element.edit,
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true
          }
        });
        this.refresh.next();
      });
      // console.log(this.leaves);
    });
    

    var db = {'year': this.year}
    this.data.getemployeeleavecount(db).subscribe((countdata: any) => {

      this.leavecount = countdata;
      // console.log(countdata);
    });

  }

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();

  showAllEvents(id: string, exid: any) {
    exid =this.datePipe.transform(new Date(exid),"dd MMM yyyy");;
    // console.log(exid);
    this.Userid = localStorage.getItem('userid');
    const databydate = { 'id': this.Userid, 'exid': exid };
    this.data.getleavedatabydate(databydate).subscribe((userdata: any) => {
      this.dayexpense = userdata;
    });
    this.modalService.open(id);
  }

  remarksmodel(id: number, modelid: string, closemodelid: string) {
    this.remarksubmit = false;
    this.Userid = localStorage.getItem('userid');
    const leaveData = { 'id': id };
    this.remarksholidayid = id;
    this.data.getleaveremarks(leaveData).subscribe((remarksData: any) => {
      // console.log(remarksData);
      this.remarksofleave = remarksData;
    });
    this.modalService.open(modelid);
    this.modalService.close(closemodelid);
  }

  yearpop(id: string) {
    this.modalService.open(id);
  }

  activeDayIsOpen: boolean = true;

  closeModal(id: string) {
    this.modalService.close(id);
  }

  closemessageModal(id: string) {
    this.modalService.close(id);
    // this.showAllEvents('event-viewer',this.remarksholidayid);   
  }

  onyearSubmit(leave) {
    this.year = leave;
    var date = this.viewDate;
    date = setYear(date, this.year);
    this.viewDate = date;
    this.Userid = localStorage.getItem('userid');
    const data = { 'id': this.Userid, 'month': this.month, 'year': this.year };
    this.data.getemployeeleavereport(data).subscribe((data: any) => {
      this.leavelistreport = data;
    });
    // this.modalService.close(id);
  }

  get f() {
    return this.leaveformGroup.controls;
  }

  get g() {
    return this.remarkformGroup.controls;
  }

  onSubmit(leave, id) {
    this.submitted = true;
    // console.log(this.leaveformGroup);
    if (this.leaveformGroup.invalid) {
      return;
    }
    

    this.disableBtn = false;
    let newDate = new Date(leave.start);
    let endDate = new Date(leave.end);

    

    const sd = new Date(leave.start);
    leave.start = Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate());
    const ed = new Date(leave.end);
    leave.end = Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate());


    // leave.start =this.datePipe.transform(new Date(leave.start),"dd MMM yyyy");
    // leave.end =this.datePipe.transform(new Date(leave.end),"dd MMM yyyy");


    // console.log(leave);

    this.data.myleaveApplyUpdate(leave).subscribe((data: any) => {
      // this.startPicker.value = '';
      // this.endPicker.value = '';
      //this.modalService.close(id);
      this.leaves = [];
      document.getElementById('closeModal').click();
      this.bindallData();
      this.bindalldatabymonth();
    });
    this.alerts.success('Leave applied successfully',true);
  }

  onSubmitRemark(remark, popid) {
    this.remarksubmit = true;
    if (this.remarkformGroup.invalid) {
      return;
    }
    this.data.addnewremark(remark.form.value).subscribe((data: any) => {
      const leaveData = { 'id': remark.form.value.remarksholidayid };
      this.data.getleaveremarks(leaveData).subscribe((remarksData: any) => {
        remark.resetForm();
        this.remarksubmit = false;
        this.remarksofleave = remarksData;
      });
      this.leaves = [];
      this.bindallData();
    });
     this.alerts.success('Note sent successfully', false);
    this.closeModal(popid);
    this.bindalldatabymonth();
   // this.showAllEvents('event-viewer', this.remarksholidayid);
  }
  listtoggel() {
  }

  onDelete(id: number, mode = '') {
    const deleteData = { 'id': id };
    if (confirm("Are you sure you want to delete?")) {
      this.data.deleteleave(deleteData).subscribe((data: any) => {
        this.leaves = [];
        //document.getElementById('closeModal').click();
        this.bindalldatabymonth();
        this.bindallData();
        if(mode=='listing'){
          document.getElementById('closeModal').click();
        }
        
      });
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  addMonthsOfYear(inc) {
    this.classactive = inc;
    var date = this.viewDate;
    date = setMonth(date, inc);
    date = setYear(date, this.year);
    this.viewDate = date;
    this.month = inc + 1;
    this.bindallData();
    this.bindalldatabymonth();
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addNewEvent(action: string, event: CalendarEvent): void {
    this.submitted = false;
    this.disableBtn = true;
    this.leave = new Leave();
    this.Userid = localStorage.getItem('userid');
    this.leave.employeeid = this.Userid;
    this.leave.start =new Date(); //new Date().toLocaleDateString();// new Date(start);
    this.leave.end =new Date(); //new Date().toLocaleDateString(); //new Date(start);
    this.leave.holidaytype = "";
    this.leave.employeeholidayapplyid = '0';
    this.leave.holidayremarkid = '0';
    this.leave.halfday = 0;
    this.leaveformGroup.reset(this.leave);
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(action: string, event: CalendarEvent, start: string): void {
    this.submitted = false;
    this.disableBtn = true;
    // this.endPicker.value = '';
    // this.leaveformGroup.reset();
    this.leave = new Leave();
    this.Userid = localStorage.getItem('userid');
    this.leave.employeeid = this.Userid;
    let newDate = new Date(start);
    this.leave.start = newDate;//new Date(start);
    this.minstartDate = this.leaveavailabledate;
    this.minendDate = newDate;
    this.leave.end = newDate;//new Date(start);
    this.leave.holidaytype = "";
    this.leave.employeeholidayapplyid = '0';
    this.leave.holidayremarkid = '0';
    this.leave.halfday = 0;
    this.leaveformGroup.reset(this.leave);
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  onDateChange(eventvalue: any) {
    // console.log(eventvalue);
    // this.minendDate = eventvalue.value;
    // this.leave.end = eventvalue.value;
  }

  editEvent(action: string, event: CalendarEvent) {
    this.submitted = false;
    this.disableBtn = true;
    this.leave = new Leave();
    this.leave.start =new Date(); //new Date().toLocaleDateString();// new Date(start);
    this.leave.end =new Date(); //new Date().toLocaleDateString(); //new Date(start);
    this.Userid = localStorage.getItem('userid');
    this.leave.employeeid = this.Userid;
    var employeeholidayapplyid = event.id;
    this.minstartDate = this.leaveavailabledate;
    this.minendDate = this.leaveavailabledate;
    this.data.getLeaveData({ 'id': employeeholidayapplyid }).subscribe((data: any) => {
      this.leave.title = data.title;
      this.leave.start = data.startdate;
      this.leave.end = data.enddate;
      this.leave.employeeholidayapplyid = data.employeeholidayapplyid;
      this.leave.holidayremarkid = data.holiodayremarksid;
      this.leave.holidayremark = data.remark.holidayremark;
      this.leave.holidaytype = data.holidaytypeid;
      this.leave.halfday = data.halfday;
      this.leave.hstatus = data.hstatus;
    });
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  editEventOfList(action: string, id: any, alleventpopid: any, event: CalendarEvent) {
    this.submitted = false;
    this.disableBtn = true;
    this.leave = new Leave();
    this.leave.start =new Date(); //new Date().toLocaleDateString();// new Date(start);
    this.leave.end =new Date(); //new Date().toLocaleDateString(); //new Date(start);
    this.Userid = localStorage.getItem('userid');
    this.leave.employeeid = this.Userid;
    var employeeholidayapplyid = id;
    this.data.getLeaveData({ 'id': employeeholidayapplyid }).subscribe((data: any) => {
      this.leave.title = data.title;
      this.leave.start = data.startdate;
      this.leave.end = data.enddate;
      this.leave.employeeholidayapplyid = data.employeeholidayapplyid;
      this.leave.holidayremarkid = data.holiodayremarksid;
      this.leave.holidayremark = data.remark.holidayremark;
      this.leave.holidaytype = data.holidaytypeid;
      this.leave.hstatus = data.hstatus;
    });
    this.closeModal(alleventpopid);
    this.modalData = { action, event };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  titleChange()
  {
    this.leavetypelist.forEach(element => {
      if(element.id == this.leave.holidaytype)
      {
        this.leave.title = element.typename;
      }
    });
  }


}