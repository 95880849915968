import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { CountryService } from '../../../services/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CheckserviceService } from '../../../services/checkservice.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-righ-to-work-history-list',
  templateUrl: './righ-to-work-history-list.component.html',
  styleUrls: ['./righ-to-work-history-list.component.css']
})
export class RighToWorkHistoryListComponent implements OnInit {
  empid:any;
  listemployee: any;

  public searchText: string;
  unassignedCases: any = [];
  selectedpage: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkService: CheckserviceService,
    private router : Router  ,
    private datePipe: DatePipe 
    ) { }

  ngOnInit() {
    
    this.empid = this.activatedRoute.snapshot.paramMap.get("empid");

   var data = {employeeid: this.empid}
    this.checkService.getrighttoworkhistorybyemployeeid(data).subscribe((righttoworklog: any) => {  
      
      righttoworklog.forEach(element => {         
        element.displayData = true; 
       });


        this.listemployee = righttoworklog;

        this.listemployee.filter(      
          option => { 
              if(option.displayData)
                return true;
          });
       

       
      this.unassignedCases = this.paginator(this.listemployee,this.selectedpage,50);


    });
  }

  shouldDisplayDate(date: Date) : boolean 
  {
    if (this.datePipe.transform(date, 'yyyy-MM-dd')  >=  this.datePipe.transform('10/02/2022', 'yyyy-MM-dd')) 
    {     
        return true;
    }  
    return false;
}

searchData(searchKey:any)
  {

    var listdata = [];

    searchKey = searchKey.trim().toLowerCase();     
    this.listemployee.forEach((element, index) => {
        if(
          element.employee.fname.trim().toLowerCase().includes(searchKey) ||
          element.employee.mname.trim().toLowerCase().includes(searchKey) ||
          element.employee.lname.trim().toLowerCase().includes(searchKey) ||
          element.employee.emailid.trim().toLowerCase().includes(searchKey) ||
          element.employee.phonenumber.trim().toLowerCase().includes(searchKey) ||
          element.nationality.countryname.trim().toLowerCase().includes(searchKey)
          )
        {
          this.listemployee[index].displayData = true;
        }
        else
        {
          this.listemployee[index].displayData = false;
        }
      });   
      
      listdata=  this.listemployee.filter(      
        option => { 
            if(option.displayData)
              return true;
        });

        this.unassignedCases = this.paginator(listdata,1,50);

  }

  completerightowork(logid){
    localStorage.SetItem('logid',logid);
    this.router.navigate(['/right-to-work-app-history']);

  }

  viewemployeerighttoworkhistory(empid)
  { 
    localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app-history', empid]);
  }
  completerighttoworkhistory(logid,empid)
  { 
    localStorage.setItem('logid', logid);
    // localStorage.SetItem('logid',logid); //localStorage.removeItem('logid');
    this.router.navigate(['/right-to-work-app',empid ]);
  }


  
  paginator(data, current_page, per_page_items) {

    
    


    let items;
   
    items = data
    let page = current_page || 1,
    per_page = per_page_items || 50,
    offset = (page - 1) * per_page,
  
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);
  
    return {
      page: page,
      perpage: per_page,
      prepage: page - 1 ? page - 1 : null,
      nextpage: (total_pages > page) ? page + 1 : null,
      total: items.length,
      totalpages: total_pages,
      data: paginatedItems,
      totalpagesarray : Array(total_pages).fill(null).map((x,i)=>i+1)
    };
  }

  selectpage(pagenumber){
    this.selectedpage = pagenumber    
      this.unassignedCases = this.paginator(this.listemployee,this.selectedpage,50);   
  }


}
